import React, { useEffect } from "react";
import {useRouter} from 'next/router';

import "bootstrap/scss/bootstrap.scss";
import "../public/scss/default/euclid-circulara.scss";

// ========= Plugins CSS START =========
import "../public/css/plugins/fontawesome.min.css";
import "../public/css/plugins/feather.css";
import "../public/css/plugins/odometer.css";
import "../public/css/plugins/animation.css";
import "../public/css/plugins/euclid-circulara.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
// ========= Plugins CSS END =========

import "../public/scss/styles.scss";

export default function App({ Component, pageProps }) {
  // Below code is added to fix routing issue on S3 static hosting
  // but this is fixed by adding trailingSlash: true in next.config.js
  // const router = useRouter();
  // console.log("Original Path: ", router.asPath)
  // const path = (/#!(\/.*)$/.exec(router.asPath) || [])[1];
  // if (path) {
  //   router.replace(path);
  //   console.log("Modified Path: ", path)
  // }

  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);
  return <Component {...pageProps} />;
}
